import React, { useState } from "react";
import "../App.css";
import "bootstrap/dist/css/bootstrap.min.css";
import { Spinner, Card, Form } from "react-bootstrap";
import logo from "../assets/images/logo.png";
import Swal from "sweetalert2";
import { useLocation, useHistory } from "react-router-dom";
import axios from "axios";

const PasswordReset = () => {
  const [password, setPassword] = useState("");
  const [repeatPassword, setRepeatPassword] = useState("");
  const [loading, setLoading] = useState(false);
  const [disabled, setDisabled] = useState(false);

  const baseUrl = process.env.REACT_APP_BASE_URL;
  const { search } = useLocation();
  const history = useHistory();

  function validatePassword() {
    const minLength = 6;
    const hasNumber = /\d/;

    let errors = [];

    if (password.length < minLength)
      errors.push("Password must be at least 6 characters long.");

    if (!hasNumber.test(password))
      errors.push("Password must contain at least one number.");

    if (password !== repeatPassword) errors.push("Passwords do not match.");

    if (errors.length > 0) {
      Swal.fire({
        title: "Error",
        text: errors[0],
        icon: "error",
        confirmButtonText: "OK",
      });

      return;
    }

    changePassword();
  }

  function changePassword() {
    const searchParams = new URLSearchParams(search);

    let token = searchParams.has("token") ? searchParams.get("token") : "";

    const body = { password, repeatPassword, token };
    const url = `${baseUrl}/v2/password/change-password`;

    setLoading(true);
    setDisabled(true);

    axios
      .put(url, body)
      .then((response) => {
        const data = response.data;
        const msg = data?.message ?? "Your password has been updated.";

        Swal.fire({
          title: "Password changed",
          text: msg,
          icon: "success",
          confirmButtonText: "OK",
        }).then(() => {
          history.push("login");
        });
      })
      .catch((error) => {
        const data = error.response.data;
        const msg = data?.message ?? "Unable to change your password.";

        Swal.fire({
          title: "Error",
          text: msg,
          icon: "error",
          confirmButtonText: "OK",
        });

        setLoading(false);
        setDisabled(false);
      });
  }

  return (
    <div className="loginContainer">
      <div
        className="d-flex justify-content-center"
        style={{
          backgroundColor: "rgba(0,0,0,0.7)",
          width: "100%",
          position: "absolute",
          top: 0,
          bottom: 0,
        }}
      >
        <Card
          style={{ backgroundColor: "rgba(255,255,255,0.7)" }}
          className="d-flex justify-content-center cardContainer loginContainer-content"
        >
          <img
            src={logo}
            style={{
              width: "15%",
              position: "absolute",
              top: 25,
              alignSelf: "center",
            }}
          />

          <h5
            className="nicfostText"
            style={{
              position: "relative",
              fontWeight: "bold",
              top: 5,
              color: "#333",
              textAlign: "center",
            }}
          >
            NIGERIAN COUNCIL OF FOOD SCIENCE AND TECHNOLOGY
            <br />
            (NiCFoST)
          </h5>
          <div className="mb-3"></div>
          <h6
            className="nicfostText"
            style={{
              position: "relative",
              marginLeft: 13,
              textAlign: "center",
              top: 45,
            }}
          >
            Change your password
          </h6>

          <div className="container" style={{ marginTop: 50 }}>
            <Form>
              <Form.Floating className="mb-3">
                <input
                  className="form-control shadow-none"
                  type="password"
                  id="password"
                  onChange={(e) => setPassword(e.target.value)}
                />
                <label htmlFor="password">Password</label>
              </Form.Floating>

              <Form.Floating className="mb-3">
                <input
                  className="form-control shadow-none"
                  type="password"
                  id="repeatPassword"
                  onChange={(e) => setRepeatPassword(e.target.value)}
                />
                <label htmlFor="password">Repeat Password</label>
              </Form.Floating>

              <div className="d-grid gap-2 mb-3" style={{ marginTop: 30 }}>
                <button
                  style={{ backgroundColor: "#003314" }}
                  type="button"
                  className="btn btn-success btn-lg"
                  onClick={(e) => validatePassword()}
                  disabled={disabled}
                >
                  {loading ? (
                    <Spinner animation="border" variant="light" size="sm" />
                  ) : (
                    "Change Password"
                  )}
                </button>
              </div>
            </Form>
          </div>
        </Card>
      </div>
    </div>
  );
};

export default PasswordReset;
