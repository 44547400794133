import React, { Component } from "react";
import "../App.css";
import "bootstrap/dist/css/bootstrap.min.css";
import { Spinner, Card, FloatingLabel, Form } from "react-bootstrap";
import logo from "../assets/images/logo.png";
import { Link } from "react-router-dom";
import Swal from "sweetalert2";
import { countries } from "../components/Countries";

import axios from "axios";

let baseUrl = process.env.REACT_APP_BASE_URL;

class Login extends Component {
  constructor(props) {
    super(props);
    this.state = {
      phone: "",
      password: "",
      selectValue: "",
      showPassword: false,
      loading: false,
      disabled: false,
      passwordFocus: false,
    };

    this.handleChange = this.handleChange.bind(this);
    this.togglePassword = this.togglePassword.bind(this);
    this.handleFocus = this.handleFocus.bind(this);
    this.handleBlur = this.handleBlur.bind(this);
  }

  handleChange(e) {
    this.setState({ selectValue: e.target.value });
  }

  togglePassword = (e) => {
    this.setState({ showPassword: e.target.checked });
  };

  handleFocus = (e) => {
    this.setState({ passwordFocus: true });
  };

  handleBlur = () => {
    this.setState({ passwordFocus: false });
  };

  getCountry() {
    return countries.map((country) => {
      return <option value={country.dial_code}>{country.name}</option>;
    });
  }

  login = async () => {
    const { password, phone } = this.state;
    const self = this;

    if (phone.length === 0) {
      Swal.fire({
        title: "Empty!",
        text: "Please enter phone number",
        icon: "error",
        confirmButtonText: "OK",
      });
    } else if (password.length === 0) {
      Swal.fire({
        title: "Empty!",
        text: "Please enter password",
        icon: "error",
        confirmButtonText: "OK",
      });
    } else {
      this.setState({ loading: true, disabled: true });

      const payload = {
        countryid: "+234",
        password: password,
        telephone: sessionStorage.getItem("phone") ?? phone,
      };

      await axios
        .post(`${baseUrl}/Users/login`, payload)
        .then((response) => {
          const { status, data } = response;

          if (status === 200) {
            this.setState({ loading: false });
            localStorage.setItem("userid", data.userid);
            localStorage.setItem("email", data.email);
            localStorage.setItem("token", data.token);
            self.props.history.push("/dashboard");
          }
        })
        .catch((error) => {
          this.setState({ loading: false, disabled: false });

          if (error?.response?.status === 409) {
            sessionStorage.setItem(
              "registration:ongoing",
              JSON.stringify(error?.response?.data)
            );
            sessionStorage.setItem(
              "registration:userId",
              JSON.stringify(error?.response?.data.userId)
            );

            const message =
              "Your registration is still in progress. You will be redirected to complete your registration.";
            Swal.fire({
              html: message,
              icon: "info",
              timerProgressBar: false,
              showConfirmButton: true,
              showCancelButton: false,
              showCloseButton: false,
              cancelButtonColor: "#ff7b7b",
              confirmButtonText: "Complete Registration",
              allowOutsideClick: false,
            }).then(() => {
              self.props.history.push("/register-food-scientist-v3");
            });
          } else if (error?.response?.status === 400) {
            Swal.fire({
              html: error?.response?.data?.message,
              icon: "error",
              timerProgressBar: true,
              showConfirmButton: false,
              showCancelButton: true,
              showCloseButton: true,
              cancelButtonColor: "#ff7b7b",
            });
          } else {
            Swal.fire({
              html:
                error?.response?.data?.message ||
                "Encountered an error while attempting to login.",
              icon: "error",
              timerProgressBar: true,
              showConfirmButton: false,
              showCancelButton: true,
              showCloseButton: true,
              cancelButtonColor: "#ff7b7b",
            });
          }
        });
    }
  };

  render() {
    const { loading, disabled, showPassword } = this.state;
    return (
      <div className="loginContainer">
        <div
          className="d-flex justify-content-center"
          style={{
            width: "100%",
            top: 0,
            bottom: 0,
          }}
        >
          <Card
            style={{ backgroundColor: "rgba(255,255,255,0.7)" }}
            className="d-flex justify-content-center cardContainer loginContainer-content"
          >
            <img
              src={logo}
              alt=""
              style={{
                width: "15%",
                position: "absolute",
                top: 18,
                alignSelf: "center",
              }}
            />

            <h5
              className="nicfostText"
              style={{
                position: "relative",
                fontWeight: "bold",
                top: 25,
                color: "#333",
                textAlign: "center",
              }}
            >
              NIGERIAN COUNCIL OF FOOD SCIENCE AND TECHNOLOGY
              <br />
              (NiCFoST)
            </h5>
            <div className="mb-3"></div>
            <h6
              style={{
                textAlign: "center",
                marginTop: 10,
                color: "red",
                fontSize: 12,
              }}
            >
              NB: Sign in if you already have an account. This applies if you
              have completed or have an ongoing registration.
            </h6>

            <div className="container" style={{ marginTop: 20 }}>
              <Form>
                <h6 className="nicfostText">Sign in to continue</h6>

                <Form.Floating className="mb-3">
                  <input
                    className="form-control shadow-none"
                    type="phone"
                    onChange={(e) => this.setState({ phone: e.target.value })}
                  />
                  <label htmlFor="email">Phone</label>
                </Form.Floating>
                <Form.Floating>
                  <input
                    className="form-control shadow-none"
                    type={showPassword ? "text" : "password"}
                    id="password"
                    onChange={(e) =>
                      this.setState({ password: e.target.value })
                    }
                  />
                  <label htmlFor="floatingPasswordCustom">Password</label>
                </Form.Floating>
                <br />
                <Form.Floating>
                  <input
                    className="shadow-none"
                    type="checkbox"
                    onChange={this.togglePassword}
                  />
                  <span>
                    {showPassword ? "Hide password" : "Show password"}
                  </span>
                </Form.Floating>

                <Link to="/password-reset" style={{ textDecoration: "none" }}>
                  <h6
                    style={{
                      textAlign: "right",
                      textTransform: "capitalize",
                      marginTop: 10,
                      color: "red",
                      fontSize: 13,
                    }}
                  >
                    Forgot password?
                  </h6>
                </Link>

                <div className="d-grid gap-2 mb-3" style={{ marginTop: 30 }}>
                  <button
                    style={{ backgroundColor: "#003314" }}
                    className="btn btn-success btn-lg"
                    onClick={(e) => this.login()}
                    disabled={disabled}
                  >
                    {loading ? (
                      <Spinner animation="border" variant="light" size="sm" />
                    ) : (
                      "Login"
                    )}
                  </button>
                </div>
              </Form>

              {/* Your registration form */}
              <div style={{ position: "relative", top: 5 }}>
                <h5 className="text-center text-dark mobile-text">
                  Don't have an account?{" "}
                  <Link
                    to="/register"
                    style={{ textDecoration: "underline", color: "green" }}
                  >
                    sign up
                  </Link>
                </h5>
              </div>
            </div>
          </Card>
        </div>
      </div>
    );
  }
}

export default Login;
