import React, { Component } from "react";
import "../App.css";
import "bootstrap/dist/css/bootstrap.min.css";
import { Spinner, Card, FloatingLabel, Form } from "react-bootstrap";
import logo from "../assets/images/logo.png";
import Swal from "sweetalert2";
import axios from "axios";

const baseUrl = process.env.REACT_APP_BASE_URL;

class PasswordResetRequest extends Component {
  constructor(props) {
    super(props);
    this.state = {
      phone: "",
      loading: false,
      disabled: false,
    };
  }

  validatePhoneNumber = () => {
    const { phone } = this.state;
    if (phone !== "") {
      this.sendPasswordResetRequest();
    } else {
      Swal.fire({
        title: "Error",
        text: "Please input a valid phone number",
        icon: "error",
        confirmButtonText: "OK",
      });
    }
  };

  sendPasswordResetRequest = () => {
    const self = this;

    const { phone } = self.state;
    self.setState({ loading: true, disabled: true });

    const params = new URLSearchParams({ phone });
    const url = `${baseUrl}/v2/password/request-reset?${params.toString()}`;

    axios
      .post(url)
      .then((response) => {
        const data = response.data;
        Swal.fire({
          title: "Password Reset Sent",
          text: data.message,
          icon: "success",
          confirmButtonText: "OK",
        }).then(() => {
          self.setState({ phone: "" });
        });
      })
      .catch((error) => {
        const data = error.response.data;
        const msg =
          data?.message ??
          "There was an error while processing your reset request.";

        Swal.fire({
          title: "Error",
          text: msg,
          icon: "error",
          confirmButtonText: "OK",
        });
      })
      .finally(() => {
        self.setState({ loading: false, disabled: false });
      });
  };

  render() {
    const { loading, disabled } = this.state;
    return (
      <div className="loginContainer">
        <div
          className="d-flex justify-content-center"
          style={{
            backgroundColor: "rgba(0,0,0,0.7)",
            width: "100%",
            position: "absolute",
            top: 0,
            bottom: 0,
          }}
        >
          <Card
            style={{ backgroundColor: "rgba(255,255,255,0.7)" }}
            className="d-flex justify-content-center cardContainer loginContainer-content"
          >
            <img
              src={logo}
              style={{
                width: "15%",
                position: "absolute",
                top: 25,
                alignSelf: "center",
              }}
            />

            <h5
              className="nicfostText"
              style={{
                position: "relative",
                fontWeight: "bold",
                top: 5,
                color: "#333",
                textAlign: "center",
              }}
            >
              NIGERIAN COUNCIL OF FOOD SCIENCE AND TECHNOLOGY
              <br />
              (NiCFoST)
            </h5>
            <div className="mb-3"></div>
            <h6
              className="nicfostText"
              style={{
                position: "relative",
                marginLeft: 13,
                textAlign: "center",
                top: 45,
              }}
            >
              Enter your registered phone number
            </h6>

            <div className="container" style={{ marginTop: 50 }}>
              <Form>
                <Form.Floating className="mb-3">
                  <input
                    className="form-control shadow-none"
                    type="phone"
                    id="phone"
                    onChange={(e) => this.setState({ phone: e.target.value })}
                  />
                  <label htmlFor="phone">Phone</label>
                </Form.Floating>

                <div className="d-grid gap-2 mb-3" style={{ marginTop: 30 }}>
                  <button
                    style={{ backgroundColor: "#003314" }}
                    type="button"
                    className="btn btn-success btn-lg"
                    onClick={(e) => this.validatePhoneNumber()}
                    disabled={disabled}
                  >
                    {loading ? (
                      <Spinner animation="border" variant="light" size="sm" />
                    ) : (
                      "Send Password Reset Link"
                    )}
                  </button>
                </div>
              </Form>
            </div>
          </Card>
        </div>
      </div>
    );
  }
}

export default PasswordResetRequest;
