import React, { Component } from "react";
import { HashRouter, Route, Switch, Redirect } from "react-router-dom";
import Dashboard from "./pages/Dashboard";
import FoodScientist from "./pages/FoodScientist";
import Licence from "./pages/Licence";
import Login from "./pages/Login";
import Logout from "./pages/Logout";
import Premises from "./pages/Premises";
import Payments from "./pages/Payments";
import Portal from "./pages/Portal";
import VerifyPayments from "./pages/VerifyPayments";
import VerifyMembership from "./pages/VerifyMembership";
import VerifyLicence from "./pages/VerifyLicence";
import Register from "./pages/Register";
import NewPassword from "./pages/NewPassword";
import PasswordResetRequest from "./pages/PasswordResetRequest";
import PasswordReset from "./pages/PasswordReset";
import RegisterFoodScientistV3 from "./pages/RegisterFoodScientistV3";
import RegistrationInstructions from "./pages/RegistrationInstructions";
import RenewFoodScientist from "./pages/RenewFoodScientist";
import RegisterLicence from "./pages/RegisterLicence";
import RegisterPremises from "./pages/RegisterPremises";
import LicenceHistory from "./pages/LicenceHistory";
import RenewPremises from "./pages/RenewPremises";
import LicenceRenewalPayment from "./pages/LicenceRenewalPayment";
import FoodScientistPayment from "./pages/FoodScientistPayment";
import PremisesRegistrationPayment from "./pages/PremisesRegistrationPayment";
import FoodScientistRenwalPayment from "./pages/FoodScientistRenewalPayment";
import Inspection from "./pages/Inspection";
import ChangePassword from "./pages/ChangePassword";
import ManageInspections from "./pages/ManageInspections";
import NewDashboard from "./pages/NewDashboard";
import NewRegisterLicence from "./pages/NewRegisterLicence";
import NewLicenceHistory from "./pages/NewLicenceHistory";
import NewRenewalLicence from "./pages/NewRenewalLicence";
import NewPremisesHistory from "./pages/NewPremisesHistory";
import NewPremisesInspection from "./pages/NewPremisesInspection";
import NewPayment from "./pages/NewPayment";
import PaymentSummary from "./pages/PaymentSummary";

class App extends Component {
  render() {
    return (
      <HashRouter>
        <Switch>
          <Route exact path="/login" component={Login} />
          <Route exact path="/register" component={Register} />
          <Route exact path="/pending-payment" component={PaymentSummary} />
          <Redirect
            exact
            from="/register-food-scientist"
            to="/register-food-scientist-v3"
          />
          <Route
            exact
            path="/register-food-scientist-v3"
            component={RegisterFoodScientistV3}
          />
          <Route
            exact
            path="/registration-instructions"
            component={RegistrationInstructions}
          />
          <Route exact path="/dashboard" component={Dashboard} />
          <Redirect
            exact
            from="/register-food-scientist"
            to="/register-food-scientist-v3"
          />
          <Route
            exact
            path="/register-food-scientist-v3"
            component={RegisterFoodScientistV3}
          />
          <Route
            exact
            path="/registration-instructions"
            component={RegistrationInstructions}
          />
          <Route
            exact
            path="/renew-food-scientist"
            component={RenewFoodScientist}
          />
          <Route exact path="/register-licence" component={RegisterLicence} />
          <Route exact path="/licence-history" component={LicenceHistory} />

          <Route exact path="/register-premises" component={RegisterPremises} />
          <Route exact path="/renew-premises" component={RenewPremises} />
          <Route exact path="/licence" component={Licence} />
          <Route exact path="/premises" component={Premises} />
          <Route exact path="/food-scientist" component={FoodScientist} />
          {/* <Route
            exact
            path="/food-scientist-record"
            component={FoodScientistRecord}
          /> */}
          <Route
            exact
            path="/food-scientist-payment"
            component={FoodScientistPayment}
          />
          <Route
            exact
            path="/food-scientist-renewal-payment"
            component={FoodScientistRenwalPayment}
          />
          <Route
            exact
            path="/register-premises-payment"
            component={PremisesRegistrationPayment}
          />
          <Route
            exact
            path="/licence-renewal-payment"
            component={LicenceRenewalPayment}
          />
          <Route exact path="/payments" component={Payments} />
          <Route exact path="/enter-otp" component={ChangePassword} />
          <Route exact path="/add-inspection" component={Inspection} />
          <Route
            exact
            path="/manage-inspections"
            component={ManageInspections}
          />
          <Route exact path="/logout" component={Logout} />
          <Route
            exact
            path="/print"
            render={() => {
              window.location.href = "print.html";
            }}
          />
          <Route exact path="/verify-payment" component={VerifyPayments} />
          <Route
            exact
            path="/password-reset"
            component={PasswordResetRequest}
          />
          <Route exact path="/change-password" component={PasswordReset} />
          <Route exact path="/portal" component={Portal} />
          <Route exact path="/verify-membership" component={VerifyMembership} />
          <Route exact path="/new-password" component={NewPassword} />
          <Route exact path="/verify-licence" component={VerifyLicence} />
          <Route exact path="/new-dashboard" component={NewDashboard} />
          <Route exact path="/new-licence" component={NewRegisterLicence} />
          <Route
            exact
            path="/new-licence-history"
            component={NewLicenceHistory}
          />
          <Route
            exact
            path="/new-renew-licence"
            component={NewRenewalLicence}
          />
          <Route
            exact
            path="/new-premises-history"
            component={NewPremisesHistory}
          />
          <Route
            exact
            path="/new-apply-premises-inspection"
            component={NewPremisesInspection}
          />
          <Route exact path="/new-payments" component={NewPayment} />
          <Route
            exact
            path="/remita"
            render={() => {
              window.location.href = "remita.html";
              return null;
            }}
          />
          <Redirect exact from="/" to="/login" />
        </Switch>
      </HashRouter>
    );
  }
}

export default App;
